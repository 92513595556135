import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Spacing } from "./Layout";
import { useGlobalState } from "../state";
import { CookieText, getCookie } from "../services/PageService";

const Title = styled(Typography)`
  padding: 0 16px;
`;

const Container = styled(Card)`
  position: fixed;
  bottom: 0;
  width: 50%;
  min-width: 900px;
  left: 0;
  right: 0;
  margin: 150px auto;
  padding: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  z-index: 2;
  @media (max-width: 990px) {
    display: block;
    min-width: 95%;
    padding: 8px;
  }
`;

const CenteredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  top: 25%;
  left: 0;
  /* transform: translate(-50%, -50%); */
  height: fit-content;
  @media (max-width: 990px) {
    top: 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 990px) {
    display: block;
    padding: 0;
  }
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 990px) {
    display: block;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 990px) {
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 8px 16px;
  }
`;

const RightMobileButton = styled(Button)`
  @media (max-width: 990px) {
    float: right;
  }
`;

const RightButton = styled(RightMobileButton)`
  justify-self: flex-end;
`;

export const GDPR = () => {
  const [cookies, setCookies] = useGlobalState("cookies");

  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [text, setText] = useState<CookieText>({});

  useEffect(() => {
    getCookie().then(setText);
  }, [setText]);

  return cookies.validate ? (
    <></>
  ) : (
    <>
      <Container>
        <ContentContainer>
          <Button
            size="small"
            color="secondary"
            onClick={() =>
              setCookies({
                perf: false,
                exp: false,
                market: false,
                validate: true,
              })
            }
          >
            Tout refuser
          </Button>
          <RightMobileButton
            size="small"
            color="secondary"
            onClick={() => setInfoModal(true)}
          >
            En savoir plus
          </RightMobileButton>
        </ContentContainer>

        <Spacing size="xs" />
        <ContentContainer>
          <Title>{text.title}</Title>
        </ContentContainer>
        <Spacing size="xs" />

        <ContentContainer>
          <FlexContainer>
            <Checkbox
              checked={cookies.exp}
              onChange={(e) =>
                setCookies({ ...cookies, exp: e.target.checked })
              }
            />
            <Typography>{text.experience}</Typography>
          </FlexContainer>
          <FlexContainer>
            <Checkbox
              checked={cookies.perf}
              onChange={(e) =>
                setCookies({ ...cookies, perf: e.target.checked })
              }
            />
            <Typography>{text.performance}</Typography>
          </FlexContainer>
          <FlexContainer>
            <Checkbox
              checked={cookies.market}
              onChange={(e) =>
                setCookies({ ...cookies, market: e.target.checked })
              }
            />
            <Typography>{text.marketing}</Typography>
          </FlexContainer>
          <RightMobileButton
            variant="contained"
            onClick={() => setCookies({ ...cookies, validate: true })}
          >
            Valider
          </RightMobileButton>
        </ContentContainer>
      </Container>
      <Modal open={infoModal} onClose={() => setInfoModal(false)}>
        <CenteredContainer>
          <TwoColumns>
            <Typography variant="h3">Cookies</Typography>
            <RightButton onClick={() => setInfoModal(false)}>
              <CloseIcon />
            </RightButton>
          </TwoColumns>
          <Spacing size="m" />
          <TwoColumns>
            <Typography variant="h4">{text.experience}</Typography>
            <Typography variant="body2">
              <ReactMarkdown>{text.experienceDesc || ""}</ReactMarkdown>
            </Typography>
          </TwoColumns>
          <Spacing size="s" />
          <Divider />
          <Spacing size="s" />
          <TwoColumns>
            <Typography variant="h4">{text.performance}</Typography>
            <Typography variant="body2">
              <ReactMarkdown>{text.performanceDesc || ""}</ReactMarkdown>
            </Typography>
          </TwoColumns>
          <Spacing size="s" />
          <Divider />
          <Spacing size="s" />
          <TwoColumns>
            <Typography variant="h4">{text.marketing}</Typography>
            <Typography variant="body2">
              <ReactMarkdown>{text.marketingDesc || ""}</ReactMarkdown>
            </Typography>
          </TwoColumns>
          <Spacing size="s" />
          <Divider />
        </CenteredContainer>
      </Modal>
    </>
  );
};
