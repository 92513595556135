import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { GradientLinear, Gradient2 } from "../styles/gradients";

export const ButtonGradientPrimary = styled(Button)`
  background: rgb(217,45,26);
  background: ${GradientLinear}
  padding-left: 32px;
  padding-right: 32px;
  color: #fff;
  text-transform: capitalize;
`;

export const ButtonGradientSecondary = styled(Button)`
  background: ${Gradient2};
`;

export const ButtonGradientPrimaryFullWidth = styled(ButtonGradientPrimary)`
  width: 100%;
`;
