import React, { useEffect } from "react";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { useGlobalState } from "../../state";
import { getPriceAges, getPricesWithFilter } from "../../services/PriceService";

export const SelectAge = () => {
  const [price, setPrice] = useGlobalState("price");
  const [ages, setAges] = useGlobalState("ages");

  useEffect(() => {
    if (ages.length > 0) {
      return;
    }

    getPriceAges().then(setAges);
  }, [ages.length, setAges]);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Âge moyen</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={price.attributes?.price_age.data.id}
        label="Age Moyen"
        onChange={({ target: { value } }) => {
          getPricesWithFilter({
            structureLabel: price.attributes?.structure.data.attributes.label,
            ageLabel: ages.find((age) => age.id === value)?.attributes.label,
            regionLabel: price.attributes?.region.data.attributes.label,
            formulaLabel: price.attributes?.formula.data.attributes.label,
          }).then((prices) => {
            if (prices[0]) {
              setPrice(prices[0]);
            }
          });
        }}
      >
        {ages.map((age) => (
          <MenuItem key={age.id} value={age.id}>
            {age.attributes.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
