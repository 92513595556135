import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Box, Button, Toolbar } from "@mui/material";

import Logo from "../logo.png";

const ImgLogo = styled.img`
  width: 60px;
  margin: auto;
`;

const ToolbarContainer = styled(Toolbar)`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  padding: 0;

  @media (max-width: 990px) {
    justify-content: space-between;
  }
`;

export const Topbar = ({ backButton = false }: { backButton?: boolean }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        color="inherit"
      >
        <Toolbar>
          <ToolbarContainer>
            {backButton && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="small" />
              </Button>
            )}

            <a href="https://lolahealth.fr">
              <ImgLogo src={Logo} />
            </a>
            {backButton && <Button></Button>}
          </ToolbarContainer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
