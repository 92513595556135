import { httpService } from "../HttpService";
import * as qs from "qs";

export interface Ccn {
  id: number;
  attributes: {
    children: boolean;
    code: string;
    familly: boolean;
    name: string;
    contact: string;
  };
}

export const getCcns = (): Promise<Ccn[]> =>
  httpService.get("/ccns").then((res) => res.data.data);

export const getCcnByCode = ({ code }: { code: string }): Promise<Ccn> =>
  httpService
    .get(
      `/ccns?${qs.stringify({
        filters: {
          code: {
            $eq: code,
          },
        },
      })}`
    )
    .then((res) => res.data.data[0]);

export const searchCcn = ({ query }: { query: string }): Promise<Ccn[]> =>
  httpService
    .get(
      `/ccns?${qs.stringify({
        filters: {
          $or: [
            {
              code: {
                $contains: query,
              },
            },
            {
              name: {
                $contains: query,
              },
            },
          ],
        },
      })}`
    )
    .then((res) => res.data.data);
