import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { Gradient1, Gradient2 } from "../styles/gradients";

export const GradientTypo = styled(Typography)<{ theme: 1 | 2 }>`
  background: ${({ theme }) => (theme === 1 ? Gradient2 : Gradient1)};
  background-clip: text;
  text-fill-color: transparent;
`;
