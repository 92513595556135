import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { Typography } from "@mui/material";

import { useGlobalState } from "../state";

import { CompareText, getCompareText } from "../services/PageService";

import { Layout, RightColumn, Spacing, Columns } from "../components/Layout";
import { CompareCard } from "../components/CompareCard";
import { getPricesWithFilter, PriceType } from "../services/PriceService";

import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const DepartementsIleDeFrance = [75, 77, 78, 91, 92, 93, 94, 95];
const DepartementsAlsace = [55, 54, 57, 67, 68];

const CompareColumns = styled(Columns)`
  justify-content: center;
  gap: 64px;
`;

const ComparePage = () => {
  const [text, setText] = useState<CompareText>({});
  const [user] = useGlobalState("user");
  const [company] = useGlobalState("company");
  const [, setPrice] = useGlobalState("price");
  const [prices, setPrices] = useState<{
    basic?: PriceType;
    premium?: PriceType;
    soloBasic?: PriceType;
    soloPremium?: PriceType;
  }>({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.firstname) {
      return navigate("/");
    }

    getCompareText().then(setText);

    const dep = parseInt(company.postalCode.slice(0, 2));
    const isIleDeFrance = DepartementsIleDeFrance.find((d) => d === dep);
    const isAlsace = DepartementsAlsace.find((d) => d === dep);

    const regionLabel = isIleDeFrance
      ? "Île-de-France"
      : isAlsace
      ? "Alsace-Moselle"
      : "Autres regions";

    const structureLabel = company.ccnObject?.attributes.familly
      ? "Familly"
      : company.ccnObject?.attributes.children
      ? "Children"
      : "Alone";

    getPricesWithFilter({
      structureLabel,
      regionLabel,
      ageLabel: "35",
    }).then((data) => {
      const basic = data.find((d) => d.attributes.formula.data.id === 1);

      const premium = data.find((d) => d.attributes.formula.data.id === 2);

      console.log(basic, premium);
      if (structureLabel !== "Familly") {
        return setPrices({ basic, premium });
      }

      getPricesWithFilter({
        regionLabel,
        ageLabel: "35",
        structureLabel: "Alone",
      }).then((data) => {
        const soloBasic = data.find((d) => d.attributes.formula.data.id === 1);

        const soloPremium = data.find(
          (d) => d.attributes.formula.data.id === 2
        );

        return setPrices({ basic, premium, soloBasic, soloPremium });
      });
    });
  }, [user, company, navigate, setText, setPrices]);

  return (
    <Layout backButton step={2}>
      <>
        <RightColumn>
          <Typography variant="h1">
            {text.title?.replace("{{name}}", user.firstname)}
          </Typography>
          <Spacing size="s" />
          <ReactMarkdown>{text.content || ""}</ReactMarkdown>
          <Spacing size="s" />
          <CompareColumns template="equal">
            <CompareCard
              theme="orange"
              price={prices.basic ? prices.basic : {}}
              text={text}
              soloPrice={prices.soloBasic ? prices.soloBasic : undefined}
              onClick={() => {
                setPrice(prices.basic ? prices.basic : {});
                navigate("/legal");
              }}
            />
            <CompareCard
              theme="red"
              price={prices.premium ? prices.premium : {}}
              soloPrice={prices.soloPremium ? prices.soloPremium : undefined}
              text={text}
              onClick={() => {
                setPrice(prices.premium ? prices.premium : {});
                navigate("/legal");
              }}
            />
          </CompareColumns>
        </RightColumn>
      </>
    </Layout>
  );
};

export default ComparePage;
