import React from "react";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { useGlobalState } from "../../state";

export const SelectParticipation = () => {
  const [company, setCompany] = useGlobalState("company");

  return (
    <FormControl fullWidth>
      <InputLabel id="participation">Taux de participation</InputLabel>
      <Select
        labelId="participation"
        value={company.participation}
        label="Taux de participation"
        onChange={({ target: { value } }) =>
          setCompany({
            ...company,
            participation:
              typeof value === "number" ? value : parseFloat(value),
          })
        }
      >
        <MenuItem value={50}>50%</MenuItem>
        <MenuItem value={60}>60%</MenuItem>
        <MenuItem value={70}>70%</MenuItem>
        <MenuItem value={80}>80%</MenuItem>
        <MenuItem value={90}>90%</MenuItem>
        <MenuItem value={100}>100%</MenuItem>
      </Select>
    </FormControl>
  );
};
