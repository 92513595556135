import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { StepMenu } from "./StepMenu";

import { Topbar } from "./Topbar";
import { Obligations } from "./Obligations";

export const Spacing = styled.div<{ size: "xs" | "s" | "m" | "l" | "xl" }>`
  height: ${({ size }) =>
    size === "xs"
      ? "8px"
      : size === "s"
      ? "16px"
      : size === "m"
      ? "32px"
      : size === "l"
      ? "64px"
      : "128px"};
`;

export const Content = styled.div`
  margin: 64px 0 0 0;
`;

type TemplateType = "equal" | "third" | "quarter";

export const Columns = styled.div<{
  template?: TemplateType;
  reverse?: boolean;
}>`
  width: 100%;
  display: flex;
  gap: 64px;
  @media (max-width: 990px) {
    flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  }
`;

export const Column = styled.div`
  padding: 64px;

  @media (max-width: 990px) {
    min-height: 0;
    padding: 16px;
  }
`;

export const LeftColumn = styled(Column)`
  min-width: 220px;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0px 2px -2px rgba(0, 0, 0, 0.2);
  background-color: #fbf9f7;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
    min-height: 0;
  }
`;

export const RightColumn = styled(Column)`
  width: 100%;
  min-height: 100vh;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 990px) {
    width: auto;
    max-width: 100%;
    margin: 0;
    box-shadow: none;
  }
`;

export const Layout = ({
  children,
  template,
  backButton,
  step,
}: {
  children: JSX.Element;
  template?: TemplateType;
  backButton?: boolean;
  step?: number;
}) => {
  return (
    <>
      <Topbar backButton={backButton} />
      <Content>
        <Container disableGutters maxWidth={false} sx={{ padding: 0 }}>
          <Columns template={template ? template : "third"} reverse>
            {step && (
              <LeftColumn>
                <StepMenu step={step} />
                <Spacing size="m" />
                <Obligations />
              </LeftColumn>
            )}
            {children}
          </Columns>
        </Container>
      </Content>
    </>
  );
};
