import React from "react";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";

type DropzoneProps = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
};

const getColor = (props: DropzoneProps) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div<DropzoneProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
  cursor: pointer;
`;

export function Dropzone({
  onDrop,
  maxFiles,
}: {
  onDrop: (acceptedFiles: File[]) => void;
  maxFiles?: number;
}) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      maxFiles,
      accept: { "image/*": [], "application/pdf": [] },
    });

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <Typography>
          Glissez vos fichier, ou clickez pour en choisir un
        </Typography>
      </Container>
    </div>
  );
}
