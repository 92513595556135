import React from "react";
import styled from "@emotion/styled";

const Container = styled.span<{ size: "m" | "l" }>`
  font-size: ${({ size }) => (size === "m" ? "16px" : "24px")};
`;

const Decimal = styled.span<{ size: "m" | "l" }>`
  font-size: ${({ size }) => (size === "m" ? "12px" : "16px")};
`;

const Symbol = styled.span<{ size: "s" | "m" | "l" }>`
  font-size: ${({ size }) =>
    size === "m" ? "14px" : size === "l" ? "22px" : "10px"};
`;

const SmallText = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const Spacing = styled.span<{ size: "m" | "l" }>`
  width: ${({ size }) => (size === "m" ? "4px" : "8px")};
`;

type PriceProps = {
  price?: number;
  priceMax?: number;
  size?: "m" | "l";
  symbol?: string;
  separator?: string;
  before?: string;
};

export const Price = ({ price, symbol, size = "m" }: PriceProps) => {
  const priceStrings = price ? price.toFixed(2).toString().split(".") : [0, 0];

  return (
    <Container size={size === "l" ? "l" : "m"}>
      {priceStrings[0]}
      <Decimal size={size === "l" ? "l" : "m"}>,{priceStrings[1]}</Decimal>
      <Spacing size="m" />
      <Symbol size={size === "l" ? "l" : "m"}>€</Symbol>
      <Symbol size={size === "l" ? "m" : "s"}>{symbol}</Symbol>
    </Container>
  );
};

export const PriceTotal = ({
  price,
  symbol,
  size,
  priceMax,
  separator,
  before,
}: PriceProps) => {
  const priceStrings = price ? price.toFixed(2).toString().split(".") : [0, 0];
  const priceStMaxrings = priceMax
    ? priceMax.toFixed(2).toString().split(".")
    : [0, 0];

  return (
    <Container size={size === "l" ? "l" : "m"}>
      <SmallText> {before} </SmallText>
      {priceStrings[0]}
      <Decimal size={size === "l" ? "l" : "m"}>,{priceStrings[1]}</Decimal>

      <Spacing size="m" />
      <SmallText> {separator} </SmallText>
      <Spacing size="m" />

      {priceStMaxrings[0]}
      <Decimal size={size === "l" ? "l" : "m"}>,{priceStMaxrings[1]}</Decimal>
      <Spacing size="m" />
      <Symbol size={size === "l" ? "m" : "s"}>€</Symbol>
      <Symbol size={size === "l" ? "m" : "s"}>{symbol}</Symbol>
    </Container>
  );
};
