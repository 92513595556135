import { httpService } from "../HttpService";

export type Steps = {
  one?: string;
  two?: string;
  three?: string;
  four?: string;
};

export const getStepMenu = (): Promise<Steps> =>
  httpService
    .get("/step-menu?populate=*")
    .then((res) => res.data.data.attributes);

export interface StepOne {
  title?: string;
  subtitle?: string;
  description?: string;
}

export const getStepOne = (): Promise<StepOne> =>
  httpService
    .get("/step-one?populate=*")
    .then((res) => res.data.data.attributes);

export interface ContactText {
  title?: string;
  subtitle?: string;
  contactTitle?: string;
  contactSubtitle?: string;
  contactButton?: string;
  infos?: [
    {
      title?: string;
      content?: string;
    }
  ];
  thanksTitle?: string;
  thanksContent?: string;
  thanksButton?: string;
}

export const getContactText = (): Promise<ContactText> =>
  httpService
    .get("/contact-page?populate=*")
    .then((res) => res.data.data.attributes);

export interface BarWithLabel {
  label?: string;
  basicValue?: number;
  preniumValue?: number;
}

export interface CompareText {
  title?: string;
  content?: string;
  cardPayCompany?: string;
  cardPayEmployee?: string;
  cardBar?: BarWithLabel[];
  cardSubtitle?: string;
  cardBarLola?: BarWithLabel[];
}

export const getCompareText = (): Promise<CompareText> =>
  httpService
    .get("/compare-page?populate=*")
    .then((res) => res.data.data.attributes);

export interface StructureSelectorText {
  title?: string;
  content?: string;
  aloneTitle?: string;
  aloneDesc?: string;
  childrenTitle?: string;
  childrenDesc?: string;
  famillyTitle?: string;
  famillyDesc?: string;
  uniformTitle?: string;
  uniformDesc?: string;
}

export const getStructureSelectorText = (): Promise<StructureSelectorText> =>
  httpService
    .get("/structure-selector?populate=*")
    .then((res) => res.data.data.attributes);

export interface LegalText {
  title?: string;
  content?: string;
  participation?: string;
}

export const getLegalText = (): Promise<LegalText> =>
  httpService
    .get("/legal-page?populate=*")
    .then((res) => res.data.data.attributes);

export interface PageDocument {
  title?: string;
  content?: string;
  button?: string;
  validateTitle?: string;
  validateContent?: string;
  validateButton?: string;
  validateImage?: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
}

export const getPageDocument = (): Promise<PageDocument> =>
  httpService
    .get("/page-document?populate=*")
    .then((res) => res.data.data.attributes);

export interface ObligationText {
  children?: string;
  familly?: string;
}

export const getObligation = (): Promise<ObligationText> =>
  httpService
    .get("/obligation?populate=*")
    .then((res) => res.data.data.attributes);

export interface CookieText {
  title?: string;
  experience?: string;
  experienceDesc?: string;
  performance?: string;
  performanceDesc?: string;
  marketing?: string;
  marketingDesc?: string;
}

export const getCookie = (): Promise<CookieText> =>
  httpService.get("/cookie?populate=*").then((res) => res.data.data.attributes);
