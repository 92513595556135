import { createTheme, ThemeOptions } from "@mui/material";

export const LolaLight: ThemeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#fb973a",
      light: "#FFED92",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#3c1518",
      contrastText: "#ffffff",
    },
    error: {
      main: "#ED4957",
    },
    warning: {
      main: "#FFB600",
    },
    info: {
      main: "#47A1F8",
    },
    success: {
      main: "#8ED778",
    },
    divider: "#C8C7C7",
    background: {
      default: "#F4F4F4",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: 32,
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: 24,
    },
    h4: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
      fontWeight: 300,
      fontFamily: "Montserrat",
    },
    h6: {
      fontSize: 12,
      fontWeight: 300,
      fontFamily: "Montserrat",
    },
    subtitle1: {
      fontFamily: "Montserrat",
    },
    subtitle2: {
      fontFamily: "Montserrat",
    },
  },
});
