import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { useGlobalState } from "../state";
import { Spacing } from "./Layout";
import { getPricesWithFilter, PriceType } from "../services/PriceService";
import {
  getStructureSelectorText,
  StructureSelectorText,
} from "../services/PageService";
import ReactMarkdown from "react-markdown";

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TypoHelp = styled.div`
  font-size: 14px;
  color: #6a6a6a;
`;

const getPrice = async ({
  structure,
  price,
}: {
  structure: string;
  price: Partial<PriceType>;
}): Promise<PriceType> => {
  const newPrice = await getPricesWithFilter({
    structureLabel: structure,
    ageLabel: price.attributes?.price_age.data.attributes.label,
    regionLabel: price.attributes?.region.data.attributes.label,
    formulaLabel: price.attributes?.formula.data.attributes.label,
  });

  return newPrice[0];
};

export const StructureSelector = () => {
  const [{ ccnObject }] = useGlobalState("company");
  const [price, setPrice] = useGlobalState("price");

  const [structure, setStructure] = useState("Alone");
  const [text, setText] = useState<StructureSelectorText>({});

  useEffect(() => {
    getStructureSelectorText().then(setText);
  }, []);

  useEffect(() => {
    setStructure(price.attributes?.structure.data.attributes.label || "");
  }, [price]);

  const onSelect = useCallback(
    (newStructure: string) => {
      getPrice({ price, structure: newStructure }).then(setPrice);
      setStructure(newStructure);
    },
    [price, setPrice, setStructure]
  );

  return (
    <ColumnContainer>
      <Typography variant="h3">{text.title}</Typography>
      <ReactMarkdown>{text.content || ""}</ReactMarkdown>

      <Spacing size="xs" />

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={structure}
          onChange={(event) => {
            onSelect(event.target.value);
          }}
        >
          {/* Salairer */}
          {!(
            ccnObject?.attributes.children || ccnObject?.attributes.familly
          ) && (
            <>
              <FormControlLabel
                value="Alone"
                control={<Radio />}
                label={text.aloneTitle}
              />

              <TypoHelp color="secondary">
                <ReactMarkdown>{text.aloneDesc || ""}</ReactMarkdown>
              </TypoHelp>
            </>
          )}

          {!ccnObject?.attributes.familly && (
            <>
              {/* Enfants */}
              <FormControlLabel
                disabled={ccnObject?.attributes.familly}
                value="Children"
                control={<Radio />}
                label={text.childrenTitle}
              />

              <TypoHelp color="secondary">
                <ReactMarkdown>{text.childrenDesc || ""}</ReactMarkdown>
              </TypoHelp>
            </>
          )}

          {/* Famille */}
          <FormControlLabel
            value="Familly"
            control={<Radio />}
            label={text.famillyTitle}
          />

          <TypoHelp color="secondary">
            <ReactMarkdown>{text.famillyDesc || ""}</ReactMarkdown>
          </TypoHelp>

          {/* Uniforme */}
          {/* <FormControlLabel
            value="Uniform"
            control={<Radio />}
            label={text.uniformTitle}
          />

          <TypoHelp color="secondary">
            <ReactMarkdown>{text.uniformDesc || ""}</ReactMarkdown>
          </TypoHelp> */}
        </RadioGroup>
      </FormControl>
    </ColumnContainer>
  );
};
