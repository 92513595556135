import React from "react";
import styled from "@emotion/styled";

import { Typography, LinearProgress } from "@mui/material";
import { Spacing } from "./Layout";
import { ButtonGradientPrimary } from "./GradientButtons";
import { GradientTypo } from "./GradientTypo";
import { CompareText } from "../services/PageService";
import { Price, PriceTotal } from "./Price";

import { useGlobalState } from "../state";
import { PriceType } from "../services/PriceService";

type ThemeProp = "orange" | "red";

export const CardContainer = styled.div<{ theme: ThemeProp }>`
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  padding: 16px;
  text-align: center;
  border: 3px solid
    ${({ theme }) => (theme === "orange" ? "#ee9a4f" : "#CA0303")};
`;

const CardDivider = styled.div`
  width: 50%;
  border-top: 1px solid #000000;
  margin: auto;
`;

const CardPrice = styled(Typography)`
  color: #5a2c01;
  font-weight: 700;
`;

const CardPay = styled.div`
  text-align: left;
`;

const CardPayEmoji = styled.span`
  display: inline-block;
  text-align: right;
  margin-right: 4px;
`;

const CardBold = styled.span`
  font-weight: 700;
  margin-right: 4px;
`;

const CardBar = styled(LinearProgress)`
  height: 8px;
  width: 180px;
  border-radius: 5px;
`;

const CardBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  @media (max-width: 990px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const DescTypo = styled(Typography)`
  padding: 0 16px;
  font-size: 12px;
`;

export const CompareCard = ({
  text,
  theme,
  price,
  soloPrice,
  onClick,
}: {
  text: CompareText;
  theme: ThemeProp;
  price: Partial<PriceType>;
  soloPrice?: Partial<PriceType>;
  onClick: () => void;
}) => {
  const [company] = useGlobalState("company");

  const multiplyBy = company.participation / 100;

  return (
    <CardContainer theme={theme}>
      <Typography variant="h3">Lola</Typography>
      <Spacing size="xs" />
      <Typography variant="h2">
        {price.attributes?.formula.data.attributes.label}
      </Typography>
      <Spacing size="xs" />
      <CardDivider />
      <Spacing size="s" />
      {soloPrice ? (
        <CardPrice>
          <PriceTotal
            price={
              soloPrice.attributes && soloPrice.attributes?.price * multiplyBy
            }
            priceMax={price.attributes && price.attributes?.price * multiplyBy}
            symbol="/mois"
            size="l"
            separator="ou"
          />
        </CardPrice>
      ) : (
        <>
          <CardPrice variant="h4">{price.attributes?.price} €/mois</CardPrice>
        </>
      )}
      <Spacing size="s" />
      <CardPay>
        <DescTypo>
          <CardPayEmoji>🏦</CardPayEmoji>
          <CardBold>
            {soloPrice ? (
              <PriceTotal
                price={
                  soloPrice.attributes &&
                  soloPrice.attributes?.price * multiplyBy
                }
                priceMax={
                  price.attributes && price.attributes?.price * multiplyBy
                }
                symbol="/mois"
                size="m"
                separator="ou"
              />
            ) : (
              <>
                <Price
                  price={
                    price.attributes && price.attributes?.price * multiplyBy
                  }
                />{" "}
                €/mois
              </>
            )}
          </CardBold>
          {text.cardPayCompany}
        </DescTypo>
        <Spacing size="xs" />
        <DescTypo>
          <CardPayEmoji>👥</CardPayEmoji>
          <CardBold>
            {soloPrice ? (
              <PriceTotal
                price={
                  soloPrice.attributes &&
                  soloPrice.attributes?.price * (1 - multiplyBy)
                }
                priceMax={
                  price.attributes && price.attributes?.price * (1 - multiplyBy)
                }
                symbol="/mois"
                size="m"
                separator="ou"
              />
            ) : (
              <>
                <Price
                  price={
                    price.attributes &&
                    price.attributes?.price * (1 - multiplyBy)
                  }
                />{" "}
                €/mois
              </>
            )}
          </CardBold>
          {text.cardPayEmployee}
        </DescTypo>
      </CardPay>
      <Spacing size="s" />
      <CardDivider />
      <Spacing size="m" />
      {text.cardBar?.map((bar, index) => (
        <React.Fragment key={index}>
          <CardBarContainer>
            <CardBold>{bar.label}</CardBold>
            <CardBar
              color={theme === "orange" ? "primary" : "error"}
              variant="determinate"
              value={theme === "orange" ? bar.basicValue : bar.preniumValue}
            />
          </CardBarContainer>
          <Spacing size="xs" />
        </React.Fragment>
      ))}
      <Spacing size="m" />
      <GradientTypo theme={1} variant="h3">
        {text.cardSubtitle}
      </GradientTypo>
      <Spacing size="m" />
      {text.cardBarLola?.map((bar, index) => (
        <React.Fragment key={index}>
          <CardBarContainer>
            <CardBold>{bar.label}</CardBold>
            <CardBar
              color={theme === "orange" ? "primary" : "error"}
              variant="determinate"
              value={theme === "orange" ? bar.basicValue : bar.preniumValue}
            />
          </CardBarContainer>
          <Spacing size="xs" />
        </React.Fragment>
      ))}
      <Spacing size="m" />
      <ButtonGradientPrimary variant="contained" onClick={onClick}>
        Choisir
      </ButtonGradientPrimary>
    </CardContainer>
  );
};
