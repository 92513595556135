import "./index.css";

import React from "react";
import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


import InfosPage from "./pages/InfosPage";
import ContactPage from "./pages/ContactPage";
import ComparePage from "./pages/ComparePage";
import LegalPage from "./pages/LegalPage";
import DocumentPage from "./pages/DocumentPage";

import { LolaLight } from "./Theme";
import App from "./App";

// import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://91463a6e4aff403dbfe877628e2b1de1@o4504094240669696.ingest.sentry.io/4504094242439169",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={LolaLight}>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/" element={<InfosPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="compare" element={<ComparePage />} />
          <Route path="legal" element={<LegalPage />} />
          <Route path="document" element={<DocumentPage />} />
        </Routes>
      </App>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
