import React from "react";
import AsyncSelect from "react-select/async";
import styled from "@emotion/styled";

import { searchCcn } from "../services/CcnService";
import { useGlobalState } from "../state";

const Container = styled.div`
  .react-select__value-container {
    padding: 14px;
  }
`;

export const SelectCCN = () => {
  const [company, setCompany] = useGlobalState("company");

  return (
    <Container>
      <AsyncSelect
        defaultOptions
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          valueContainer: (base) => ({
            ...base,
            padding: 14,
          }),
        }}
        loadOptions={(query, callback) => {
          searchCcn({ query }).then((datas) =>
            callback(
              datas.map((data) => ({
                value: data,
                label: `${data.attributes.code} - ${data.attributes.name}`,
              }))
            )
          );
        }}
        value={
          company.ccnObject
            ? {
                value: company.ccnObject,
                label: `${company.ccnObject?.attributes.code} - ${company.ccnObject?.attributes.name}`,
              }
            : undefined
        }
        onChange={(option) => {
          if (!option || !option.value) {
            return;
          }

          setCompany({
            ...company,
            ccnObject: option.value,
            ccnLabel: option.label,
            ccnCode: option.value.attributes.code,
          });
        }}
        placeholder="Convention Collective (CCN)"
      />
    </Container>
  );
};
