import { createGlobalState } from "react-hooks-global-state";
import { Ccn } from "./services/CcnService";
import { PriceType, PriceAge, Region } from "./services/PriceService";

export type Subscribe = {
  ccn: string;
  ccnObject?: Ccn;
  regionId: number;
  numberOfEmployee: number;
  participation: number;
  ageId: number;
  type: "basic" | "prenium" | undefined;
};

export interface Company {
  name: string;
  siret: string;
  ape: string;
  startDate: Date;
  cancellation: boolean;
  address: string;
  city: string;
  postalCode: string;
  ccnCode: string;
  ccnObject?: Ccn;
  ccnLabel: string;
  numberOfEmployee: number;
  participation: number;
};

export type Cancellation = {
  insuranceName: string;
  subscribtionDate: Date;
  contractNumber: string;
};

export type User = {
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
};

type State = {
  ccns: Ccn[];
  company: Company;
  cancellation: Cancellation;
  structure: string;
  subscribe: Subscribe;
  user: User;
  contact: boolean;
  contactSend: boolean;
  ages: PriceAge[];
  regions: Region[];
  price: Partial<PriceType>;
  offer: number;
  selectedPrices: {
    basic: PriceType[];
    prenium: PriceType[];
  };
  cookies: {
    perf: boolean;
    exp: boolean;
    validate: boolean;
    market: boolean;
  };
  end: boolean;
};

const defaultState: State = {
  ccns: [],
  subscribe: {
    ccn: "",
    regionId: 1,
    numberOfEmployee: 5,
    participation: 50,
    ageId: 1,
    type: undefined,
  },
  user: {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  },
  company: {
    name: "",
    siret: "",
    ape: "",
    address: "",
    city: "",
    postalCode: "",
    startDate: new Date(),
    cancellation: false,
    ccnCode: "",
    ccnLabel: "",
    numberOfEmployee: 5,
    participation: 50,
  },
  cancellation: {
    insuranceName: "",
    subscribtionDate: new Date(),
    contractNumber: "",
  },
  offer: 1,
  contact: false,
  contactSend: false,
  structure: "Alone",
  ages: [],
  regions: [],
  price: {},
  selectedPrices: {
    basic: [],
    prenium: [],
  },
  cookies: {
    perf: true,
    exp: true,
    market: true,
    validate: false,
  },
  end: false
};

const storage = window.localStorage.getItem("store");

let initialState = { ...defaultState };
if (storage) {
  initialState = JSON.parse(storage ? storage : "");
  if (initialState.company) {
    initialState.company.startDate = new Date(initialState.company.startDate);
    initialState.cancellation.subscribtionDate = new Date(
      initialState.cancellation.subscribtionDate
    );
  }
}

export const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState<State>({ ...defaultState, ...initialState });

window.addEventListener("beforeunload", (ev) => {
  ev.preventDefault();

  const cookies = getGlobalState("cookies");
  const end = getGlobalState("end");

  if (!cookies.exp || !cookies.validate) {
    window.localStorage.setItem("store", JSON.stringify({ cookies }));
    return;
  }

  if(end) {
    window.localStorage.setItem("store", JSON.stringify({ }));
    return;
  }

  const store = {
    cookies,
    ccns: getGlobalState("ccns"),
    company: getGlobalState("company"),
    cancellation: getGlobalState("cancellation"),
    structure: getGlobalState("structure"),
    subscribe: getGlobalState("subscribe"),
    user: getGlobalState("user"),
    contact: getGlobalState("contact"),
    contactSend: getGlobalState("contactSend"),
    ages: getGlobalState("ages"),
    regions: getGlobalState("regions"),
    price: getGlobalState("price"),
    offer: getGlobalState("offer"),
    selectedPrices: getGlobalState("selectedPrices"),
  };

  window.localStorage.setItem("store", JSON.stringify(store));
});
