import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TextField, Typography, Button } from "@mui/material";

import { useGlobalState } from "../state";

import { ContactText, getContactText } from "../services/PageService";

import { Layout, Spacing, Column } from "../components/Layout";
import { ButtonGradientPrimary } from "../components/GradientButtons";
import { postContact } from "../services/MarketingService";

const TextInput = styled(TextField)`
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const InfoPointContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

const Icon = styled(CheckCircleIcon)`
  width: 1.5em;
  height: 1.5em;
  color: #fb973a;
`;

const ContactPage = () => {
  const [text, setText] = useState<ContactText>({});
  const [send, setSend] = useState<boolean>(false);

  const [company] = useGlobalState("company");
  const [user, setUser] = useGlobalState("user");

  useEffect(() => {
    getContactText().then(setText);
  }, [setText]);

  return (
    <Layout template="equal" backButton>
      <>
        <Column>
          <Typography variant="h1">{text.title}</Typography>
          <Spacing size="s" />
          <Typography variant="h3">{text.subtitle}</Typography>
          <Spacing size="m" />

          {text.infos?.map((info, index) => (
            <React.Fragment key={index}>
              <InfoPointContainer>
                <Icon />
                <div>
                  <Typography variant="h3">{info.title}</Typography>
                  <ReactMarkdown>{info.content || ""}</ReactMarkdown>
                </div>
              </InfoPointContainer>
              <Spacing size="s" />
            </React.Fragment>
          ))}
          <Spacing size="m" />
        </Column>
        <Column>
          {!send ? (
            <Center>
              <form>
                <Spacing size="l" />
                <Typography variant="h2">{text.contactTitle}</Typography>
                <Spacing size="m" />
                <Typography variant="h3">{text.contactSubtitle}</Typography>
                <Spacing size="m" />
                <TextInput
                  required
                  label="Email"
                  type="email"
                  value={user.email}
                  onChange={({ target: { value } }) =>
                    setUser({ ...user, email: value })
                  }
                />
                <Spacing size="m" />
                <TextInput
                  required
                  label="Telephone"
                  type="phone"
                  value={user.phone}
                  onChange={({ target: { value } }) =>
                    setUser({ ...user, phone: value })
                  }
                />
                <Spacing size="m" />
                <ButtonGradientPrimary
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    postContact({ user, company }).then(() => setSend(true));
                  }}
                >
                  {text.contactButton}
                </ButtonGradientPrimary>
                <Spacing size="m" />
              </form>
            </Center>
          ) : (
            <Center>
              <Spacing size="l" />
              <Typography variant="h2">{text.thanksTitle}</Typography>
              <Spacing size="m" />
              <ReactMarkdown>{text.thanksContent || ""}</ReactMarkdown>
              <Spacing size="m" />
              <Button variant="contained" href="https://lolahealth.fr">
                {text.thanksButton}
              </Button>
            </Center>
          )}
        </Column>
      </>
    </Layout>
  );
};

export default ContactPage;
