import "./App.css";

import React, { ReactNode, useEffect } from "react";

import { useScrollToTop } from "./hooks/useScrollToTop";

import { GDPR } from "./components/GDPR";

type AppProps = {
  children: ReactNode;
};

const App = ({ children }: AppProps) => {
  useScrollToTop();

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP || '';

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, [])

  return (
    <>
      {children}
      <GDPR />
    </>
  );
};

export default App;
