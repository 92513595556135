import React from "react";
import styled from "@emotion/styled";

import { TextField } from "@mui/material";

import { useGlobalState } from "../../state";

const TextInput = styled(TextField)`
  width: 100%;
`;

export const InputEmployees = () => {
  const [company, setCompany] = useGlobalState("company");

  return (
    <TextInput
      id="outlined-basic"
      label="Nombre d'employés"
      variant="outlined"
      type="number"
      value={company.numberOfEmployee}
      onChange={({ target: { value } }) => {
        const targetValue = parseFloat(value);
        let newValue = targetValue;

        if (targetValue < 1) {
          newValue = 1;
        }

        setCompany({
          ...company,
          numberOfEmployee: newValue,
        });
      }}
    />
  );
};
