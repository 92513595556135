import axios from "axios";
import { Company } from "../state";

type Convention = {
  shortTitle: string;
  idcc: number;
};

type Establishment = {
  activitePrincipaleEtablissement: string;
  address: string;
  categorieEntreprise: string;
  codeCommuneEtablissement: string;
  codePostalEtablissement: string;
  etablissementSiege: true;
  etatAdministratifEtablissement: string;
  idccs: string[];
  libelleCommuneEtablissement: string;
  siret: string;
};

type CompanyDetail = {
  activitePrincipale: string;
  activitePrincipaleUniteLegale: string;
  caractereEmployeurUniteLegale: string;
  categorieJuridiqueUniteLegale: string;
  conventions: Convention[];
  dateCreationUniteLegale: string;
  dateDebut: string;
  etablissements: 3;
  etatAdministratifUniteLegale: string;
  firstMatchingEtablissement: Establishment;
  highlightLabel: string;
  label: string;
  matching: 1;
  simpleLabel: string;
  siren: string;
};

const regExAddress = (company: CompanyDetail) => {
  const address =
    company.firstMatchingEtablissement.address.split(/(\s+([a-zA-Z]+\s+)+)/i);
  return `${address[0]} ${address[1]}`;
};

export const getCompanyInfos = ({
  query,
}: {
  query: string;
}): Promise<Company[]> =>
  axios
    .get(
      "https://api.recherche-entreprises.fabrique.social.gouv.fr/api/v1/search",
      {
        params: { query },
      }
    )
    .then((res) => {
      return res.data.entreprises.map(
        (company: CompanyDetail): Company => ({
          name: company.label,
          siret: company.firstMatchingEtablissement.siret,
          ape: company.activitePrincipaleUniteLegale,
          startDate: new Date(),
          cancellation: false,
          address: regExAddress(company),
          city: company.firstMatchingEtablissement.libelleCommuneEtablissement,
          postalCode:
            company.firstMatchingEtablissement.codePostalEtablissement,
          ccnCode: company.conventions[0]
            ? `${company.conventions[0].idcc}`
            : "",
          ccnLabel: company.conventions[0]
            ? company.conventions[0].shortTitle
            : "",
          numberOfEmployee: 5,
          participation: 50,
        })
      );
    })
    .catch(() => []);
