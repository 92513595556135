export const Gradient1 = `linear-gradient(
  170deg,
  rgba(251, 151, 58, 1) 0%,
  rgba(216, 25, 42, 1) 100%
);`;

export const Gradient2 = `linear-gradient(
170deg, 
rgba(255,182,0,1) 0%, 
rgba(255,121,19,1) 50%, 
rgba(216, 25, 42, 1) 100%
);`;

export const GradientLinear = `linear-gradient(90deg, rgba(217,45,26,1) 0%, rgba(251,151,58,1) 100%);`;
