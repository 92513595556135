import { httpService } from "../HttpService";
import { Cancellation, Company, User } from "../state";
import { PriceType } from "./PriceService";

export const postContact = (params: {
  user: User;
  company: Company;
}): Promise<boolean> =>
  httpService.post("/subscribe/contact", params).then((res) => res.data.data);

export const postContrat = (params: {
  user: User;
  company: Company;
  cancellation: Cancellation;
  price: Partial<PriceType>;
}): Promise<boolean> =>
  httpService.post("/subscribe/contrat", params).then((res) => res.data.data);
