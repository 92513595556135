import { httpService } from "../HttpService";
import * as qs from "qs";

export interface Base {
  id: number;
  attributes: {
    label: string;
  };
}

export interface PriceAge extends Base { }

export interface Region extends Base { }

export interface Formula extends Base { }

export interface Structure extends Base { }

export interface PriceType {
  id: number;
  attributes: {
    price: number;
    formula: { data: Formula };
    region: { data: Region };
    price_age: { data: PriceAge };
    structure: { data: Structure };
  };
}

export const getPriceAges = (): Promise<PriceAge[]> =>
  httpService.get(`/price-ages?${qs.stringify({ sort: ['id:asc'] })}`).then((res) => res.data.data);

export const getRegions = (): Promise<Region[]> =>
  httpService.get("/regions").then((res) => res.data.data);

export const getStructure = (): Promise<Structure[]> =>
  httpService.get("/structure").then((res) => res.data.data);

export const getPrices = (): Promise<PriceType[]> =>
  httpService.get("/prices?populate=*").then((res) => res.data.data);

const getLabelFilter = (filter: string, label?: string) =>
  label ? { [filter]: { label: { $contains: label } } } : {};

export const getPricesWithFilter = ({
  regionLabel,
  structureLabel,
  ageLabel,
  formulaLabel,
}: {
  regionLabel?: string;
  structureLabel?: string;
  ageLabel?: string;
  formulaLabel?: string;
}): Promise<PriceType[]> =>
  httpService
    .get(
      `/prices?populate=*&${qs.stringify({
        filters: {
          ...getLabelFilter("region", regionLabel),
          ...getLabelFilter("structure", structureLabel),
          ...getLabelFilter("price_age", ageLabel),
          ...getLabelFilter("formula", formulaLabel),
        },
      })}`
    )
    .then((res) => res.data.data);
