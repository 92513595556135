import { httpService } from "../HttpService";
import * as qs from "qs";
import format from "date-fns/format";

import { Company, User, Cancellation } from '../state'
import { PriceType } from "./PriceService";

export interface LeadCompany {
  name: string,
  siret: string,
  ape: string,
  address: string,
  city: string,
  postalCode: string,
  numberOfEmployee: number,
  participation: number,
  ccn?: number,
  rib: number,
  kbis: number,
  idcard: number,
  price?: number,
  startDate: string,
  cancelName?: string,
  cancelNumber?: string,
  cancelDate?: string,
  contact: number,
}

type PostNewLeadProps = {
  kbis: File,
  id: File[],
  rib: File
  user: User,
  company: Company,
  price: Partial<PriceType>,
  cancellation: Cancellation
}

export const postNewLead = async (data: PostNewLeadProps) => {

  const kbis = await postFile(data.kbis)
  const idcard = await postFile(data.id[0])
  const rib = await postFile(data.rib)

  const contactExist: { id: number } = await httpService.get(`contacts?${qs.stringify({
    filters: {
      email: {
        $eq: data.user.email,
      },
    },
  })}`).then(res => res.data.data[0])

  const contact = contactExist ? contactExist : await postCompanyContact(data.user)

  console.log(contact)

  let newLead: LeadCompany = {
    ...data.company,
    price: data.price.id,
    ccn: data.company.ccnObject?.id,
    contact: contact.id,
    kbis: kbis.id,
    idcard: idcard.id,
    rib: rib.id,
    startDate: format(data.company.startDate, 'yyyy-MM-dd'),
  }

  if (data.company.cancellation) {
    newLead = {
      ...newLead,
      cancelNumber: data.cancellation.contractNumber,
      cancelName: data.cancellation.insuranceName,
      cancelDate: data.cancellation.subscribtionDate
        ? format(data.cancellation.subscribtionDate, 'yyyy-MM-dd')
        : '',
    }
  }

  return await postCompany(newLead);
}

export const postFile = (files: File): Promise<{ id: number }> => {
  const data = new FormData();
  data.append(`files`, files);

  return httpService.post('upload', data).then(res => res.data[0])
}

export const postCompanyContact = (user: User): Promise<{ id: number }> => httpService
  .post('contacts', { data: user })
  .then(res => res.data.data)

export const postCompany = (leadCompany: LeadCompany): Promise<LeadCompany> => httpService
  .post('companies', { data: leadCompany })
  .then(res => res.data)
