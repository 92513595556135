import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";
import { TextField, Typography, LinearProgress } from "@mui/material";

import { Company, useGlobalState } from "../state";

import { useDebounce } from "../hooks/useDebounce";

import { getCcnByCode } from "../services/CcnService";
import { getStepOne, StepOne } from "../services/PageService";
import { getCompanyInfos } from "../services/SireneService";

import { Layout, Spacing, RightColumn } from "../components/Layout";
import { InputEmployees } from "../components/PriceForm/InputEmployees";
import { SelectCCN } from "../components/SelectCCN";
import { ButtonGradientPrimary } from "../components/GradientButtons";

const TextInput = styled(TextField)`
  width: 100%;
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const TextButton = styled.span`
  color: #fb973a;
  cursor: pointer;
`;

const CompanyCard = styled.div`
  width: 100%;
  border: 1px solid #00000078;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    border: 1px solid #fb973a;
  }
`;

const CompanyCardContent = styled.div`
  padding: 16px;
`;

const FloatButton = styled(ButtonGradientPrimary)`
  float: right;
`;

const InfosPage = () => {
  const [company, setCompany] = useGlobalState("company");
  const [user, setUser] = useGlobalState("user");

  const [text, setText] = useState<StepOne>({});
  const [displayAddress, setDisplayAddress] = useState<boolean>(
    company.address !== ""
  );
  const [companies, setCompanies] = useState<Company[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const query: string = useDebounce<string>(company.name, 500);

  const navigate = useNavigate();

  useEffect(() => {
    getStepOne().then(setText);
  }, [setText]);

  useEffect(() => {
    getCompanyInfos({ query })
      .then(setCompanies)
      .then(() => setLoading(false));
  }, [query, setLoading]);

  const onSelectCompany = useCallback(
    async (company: Company) => {
      const ccnObject = await getCcnByCode({ code: company.ccnCode });

      setCompany({ ...company, ccnObject });
      setDisplayAddress(true);
    },
    [setCompany, setDisplayAddress]
  );

  return (
    <Layout step={1}>
      <>
        <RightColumn>
          <Typography variant="h1">{text.title}</Typography>
          <Spacing size="s" />
          <ReactMarkdown>{text.description || ""}</ReactMarkdown>
          <Spacing size="m" />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                company.numberOfEmployee > 20 ||
                company.ccnObject?.attributes.contact
              ) {
                return navigate("/contact");
              }

              navigate("/compare");
            }}
          >
            <TextInput
              required
              label="Prénom"
              variant="outlined"
              value={user.firstname}
              onChange={({ target: { value } }) =>
                setUser({ ...user, firstname: value })
              }
            />
            <Spacing size="m" />
            <TextInput
              required
              label="Nom"
              variant="outlined"
              value={user.lastname}
              onChange={({ target: { value } }) =>
                setUser({ ...user, lastname: value })
              }
            />
            <Spacing size="m" />

            <InputEmployees />

            <Spacing size="m" />

            <TextInput
              required
              label={
                displayAddress
                  ? "Raison sociale"
                  : "Raison sociale ou numéro SIREN/SIRET"
              }
              variant="outlined"
              value={company.name}
              onChange={(event) => {
                setLoading(true);
                setCompany({ ...company, name: event.target.value });
              }}
            />

            {!displayAddress && (
              <>
                <Spacing size="xs" />
                <Typography>
                  ou{" "}
                  <TextButton onClick={() => setDisplayAddress(true)}>
                    Entrer vos informations manuellement
                  </TextButton>{" "}
                </Typography>

                <Spacing size="s" />

                {loading && <LinearProgress color="primary" />}

                <Spacing size="s" />

                {!loading &&
                  companies?.map((companyDetail, index) => (
                    <div key={index}>
                      <CompanyCard
                        onClick={() => onSelectCompany(companyDetail)}
                      >
                        <CompanyCardContent>
                          <Typography>
                            <BoldSpan>{companyDetail.name}</BoldSpan> -{" "}
                            {companyDetail.siret}
                          </Typography>
                          <Spacing size="xs" />
                          <Typography>
                            {companyDetail.address}, {companyDetail.postalCode},{" "}
                            {companyDetail.city}
                          </Typography>
                        </CompanyCardContent>
                      </CompanyCard>
                      <Spacing size="s" />
                    </div>
                  ))}
              </>
            )}

            {displayAddress && (
              <>
                <Spacing size="xs" />
                <Typography>
                  ou{" "}
                  <TextButton onClick={() => setDisplayAddress(false)}>
                    Rechercher votre entreprise
                  </TextButton>{" "}
                </Typography>

                <Spacing size="m" />

                <SelectCCN />

                <Spacing size="m" />

                <TextInput
                  required
                  label="Siret"
                  value={company?.siret}
                  onChange={(event) =>
                    setCompany({ ...company, siret: event.target.value })
                  }
                />

                <Spacing size="m" />

                <TextInput
                  required
                  label="Code APE"
                  value={company?.ape}
                  onChange={(event) =>
                    setCompany({ ...company, ape: event.target.value })
                  }
                />

                <Spacing size="m" />

                <TextInput
                  required
                  label="Adresse"
                  value={company?.address}
                  onChange={(event) =>
                    setCompany({ ...company, address: event.target.value })
                  }
                />

                <Spacing size="m" />

                <TextInput
                  required
                  label="Code Postal"
                  value={company?.postalCode}
                  onChange={(event) =>
                    setCompany({ ...company, postalCode: event.target.value })
                  }
                />

                <Spacing size="m" />

                <TextInput
                  required
                  label="Ville"
                  value={company?.city}
                  onChange={(event) =>
                    setCompany({ ...company, city: event.target.value })
                  }
                />

                <Spacing size="m" />
              </>
            )}

            <FloatButton
              variant="contained"
              size="large"
              type="submit"
              disabled={loading}
            >
              Valider
            </FloatButton>

            <Spacing size="m" />
          </form>
        </RightColumn>
      </>
    </Layout>
  );
};

export default InfosPage;
