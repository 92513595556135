import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import { getStepMenu, Steps } from "../services/PageService";

import { Spacing } from "./Layout";

const Container = styled.div`
  top: 96px;
`;

const MenuItem = styled(Typography)`
  font-weight: 700;
`;

export const StepMenu = ({ step }: { step: number }) => {
  const [menu, setMenu] = useState<Steps>({});

  useEffect(() => {
    getStepMenu().then(setMenu);
  }, [setMenu]);

  return (
    <Container>
      <Typography variant="h4">Étapes</Typography>
      <Spacing size="m" />

      <MenuItem
        variant="h5"
        sx={{ color: step === 1 ? "#3c1518" : "#00000040" }}
      >
        1. {menu.one}
      </MenuItem>
      <Spacing size="m" />
      <MenuItem
        variant="h5"
        sx={{ color: step === 2 ? "#3c1518" : "#00000040" }}
      >
        2. {menu.two}
      </MenuItem>
      <Spacing size="m" />
      <MenuItem
        variant="h5"
        sx={{ color: step === 3 ? "#3c1518" : "#00000040" }}
      >
        3. {menu.three}
      </MenuItem>
      <Spacing size="m" />
      <MenuItem
        variant="h5"
        sx={{ color: step === 4 ? "#3c1518" : "#00000040" }}
      >
        4. {menu.four}
      </MenuItem>
      <Spacing size="m" />
    </Container>
  );
};
