import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker.css";

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import { TextField, Typography } from "@mui/material";

import { useGlobalState } from "../state";

import { getLegalText, LegalText } from "../services/PageService";

import { Layout, RightColumn, Spacing, Columns } from "../components/Layout";

import { useNavigate } from "react-router-dom";
import { PriceCard } from "../components/PriceCard";
import { StructureSelector } from "../components/StructureSelector";
import { SelectAge } from "../components/PriceForm/SelectAge";
import { SelectParticipation } from "../components/PriceForm/SelectParticipation";
import { ButtonGradientPrimaryFullWidth } from "../components/GradientButtons";

registerLocale("fr", fr);

const TextInput = styled(TextField)`
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const TypoHelp = styled.div`
  font-size: 14px;
  color: #6a6a6a;
`;

const LegalPage = () => {
  const [user, setUser] = useGlobalState("user");
  const [price] = useGlobalState("price");
  const [company, setCompany] = useGlobalState("company");

  const [text, setText] = useState<LegalText>({});

  const navigate = useNavigate();

  useEffect(() => {
    getLegalText().then(setText);
  }, [setText]);

  useEffect(() => {
    if (!price || !price.id) {
      return navigate("/");
    }
  }, [navigate, price]);

  return (
    <Layout backButton step={3}>
      <>
        {price && (
          <>
            <RightColumn>
              <Typography variant="h1">
                {text.title?.replace(
                  "{{formula}}",
                  price.attributes
                    ? price.attributes?.formula.data.attributes.label
                    : ""
                )}
              </Typography>
              <Spacing size="s" />
              <ReactMarkdown>{text.content || ""}</ReactMarkdown>
              <Spacing size="m" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  navigate("/document");
                }}
              >
                <Columns>
                  <FormContainer>
                    <Typography variant="h3">Votre entreprise</Typography>
                    <Spacing size="m" />
                    <SelectAge />
                    <Spacing size="m" />
                    <SelectParticipation />
                    <TypoHelp color="secondary">
                      <ReactMarkdown>{text.participation || ""}</ReactMarkdown>
                    </TypoHelp>
                    <Spacing size="s" />
                    <Typography variant="h3">Vos informations</Typography>
                    <Spacing size="m" />
                    <TextInput
                      required
                      label="Email"
                      variant="outlined"
                      type="email"
                      value={user.email}
                      onChange={({ target: { value } }) =>
                        setUser({ ...user, email: value })
                      }
                    />
                    <Spacing size="m" />
                    <TextInput
                      required
                      label="Téléphone "
                      variant="outlined"
                      type="phone"
                      value={user.phone}
                      onChange={({ target: { value } }) =>
                        setUser({ ...user, phone: value })
                      }
                    />
                    <Spacing size="m" />
                    <Typography variant="h3">
                      Date de début de contrat
                    </Typography>
                    <Spacing size="xs" />
                    <DatePicker
                      minDate={new Date()}
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      selected={company.startDate}
                      onChange={(startDate: Date) =>
                        setCompany({ ...company, startDate })
                      }
                    />
                    <Spacing size="m" />
                    <StructureSelector />
                    <Spacing size="m" />
                    <ButtonGradientPrimaryFullWidth type="submit">
                      Valider
                    </ButtonGradientPrimaryFullWidth>
                  </FormContainer>
                  <PriceCard />
                </Columns>
              </form>
            </RightColumn>
          </>
        )}
      </>
    </Layout>
  );
};

export default LegalPage;
