import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker.css";

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import fr from "date-fns/locale/fr";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";

import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { useGlobalState } from "../state";

import { PageDocument, getPageDocument } from "../services/PageService";
import { postNewLead } from "../services/CompanyService";

import { Layout, RightColumn, Spacing, Columns } from "../components/Layout";
import { Dropzone } from "../components/Dropzone";
import { PriceCard } from "../components/PriceCard";
import {
  ButtonGradientPrimary,
  ButtonGradientPrimaryFullWidth,
} from "../components/GradientButtons";
import { postContrat } from "../services/MarketingService";

registerLocale("fr", fr);

const TextInput = styled(TextField)`
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const FileContainer = styled.div`
  background-color: #fafafa;
  padding: 16px;
  border: 1px solid #3c1518;
  border-radius: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

const FileName = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
  max-width: 160px;
`;

const ClearStyledIcon = styled(ClearIcon)`
  padding: 4px;
  cursor: pointer;
  :hover {
    color: #ed4957;
  }
`;

const StyledModal = styled(Modal)`
  overflow-y: scroll;
`;

const ModalContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  padding: 64px;
  margin: 32px auto;
  background-color: #ffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  text-align: center;
  @media (max-width: 990px) {
    margin: 0;
    padding: 16px 0;
    height: 100%;
  }
`;

const ModalGif = styled.img`
  border-radius: 16px;
  max-width: 60%;
`;

const DocumentPage = () => {
  const [, setEnd] = useGlobalState("end");
  const [user] = useGlobalState("user");
  const [price] = useGlobalState("price");
  const [company, setCompany] = useGlobalState("company");
  const [cancellation, setCancellation] = useGlobalState("cancellation");

  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<File[]>([]);
  const [kbis, setKbis] = useState<File>();
  const [rib, setRib] = useState<File>();
  const [open, setOpen] = useState<boolean>(false);

  const [text, setText] = useState<PageDocument>({});

  const navigate = useNavigate();

  useEffect(() => {
    getPageDocument().then(setText);
  }, [setText]);

  useEffect(() => {
    if (!user || !user.email) {
      return navigate("/compare");
    }
  }, [navigate, user]);

  return (
    <Layout backButton step={4}>
      <>
        {user && (
          <>
            <RightColumn>
              <Typography variant="h1">{text.title}</Typography>
              <Spacing size="s" />
              <ReactMarkdown>{text.content || ""}</ReactMarkdown>
              <Spacing size="m" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (!kbis || !id || !rib) {
                    return
                  }

                  setLoading(true)
                  postNewLead({
                    kbis,
                    id,
                    rib,
                    company,
                    user,
                    price,
                    cancellation
                  }).then(() => {
                    postContrat({ user, company, cancellation, price })
                    setOpen(true)
                    setEnd(true)
                  }).finally(() => {
                    setLoading(false)
                  })
                }}
              >
                <Columns>
                  <FormContainer>
                    <Typography variant="h3">Document d'identité</Typography>
                    {id.length < 2 && (
                      <>
                        <Spacing size="m" />
                        <Dropzone
                          maxFiles={2}
                          onDrop={(acceptedFiles: File[]) => {
                            setId([...id, ...acceptedFiles]);
                          }}
                        />
                      </>
                    )}

                    <Spacing size="m" />

                    {id?.map((file, number) => (
                      <div key={`${file.name}+${number}`}>
                        <FileContainer>
                          <ImageIcon />
                          <FileName variant="body2">{file.name}</FileName>
                          <ClearStyledIcon
                            onClick={() => {
                              setId([
                                ...id.filter(
                                  (element) => element.name !== file.name
                                ),
                              ]);
                            }}
                          />
                        </FileContainer>
                        <Spacing size="m" />
                      </div>
                    ))}

                    <Typography variant="h3">KBIS (- de 3 mois)</Typography>
                    {!kbis && (
                      <>
                        <Spacing size="m" />
                        <Dropzone
                          maxFiles={1}
                          onDrop={(acceptedFiles: File[]) => {
                            setKbis(acceptedFiles[0]);
                          }}
                        />
                      </>
                    )}

                    <Spacing size="m" />

                    {kbis && (
                      <div key={`${kbis.name}+kbis`}>
                        <FileContainer>
                          <ImageIcon />
                          <FileName variant="body2">{kbis.name}</FileName>
                          <ClearStyledIcon
                            onClick={() => {
                              setKbis(undefined);
                            }}
                          />
                        </FileContainer>
                        <Spacing size="m" />
                      </div>
                    )}

                    <Typography variant="h3">RIB</Typography>
                    {!rib && (
                      <>
                        <Spacing size="m" />
                        <Dropzone
                          maxFiles={1}
                          onDrop={(acceptedFiles: File[]) => {
                            setRib(acceptedFiles[0]);
                          }}
                        />
                      </>
                    )}

                    <Spacing size="m" />

                    {rib && (
                      <div key={`${rib.name}+rib`}>
                        <FileContainer>
                          <ImageIcon />
                          <FileName variant="body2">{rib.name}</FileName>
                          <ClearStyledIcon
                            onClick={() => {
                              setRib(undefined);
                            }}
                          />
                        </FileContainer>
                        <Spacing size="m" />
                      </div>
                    )}

                    <Typography variant="h3">Résiliation</Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={company.cancellation}
                            onChange={() =>
                              setCompany({
                                ...company,
                                cancellation: !company.cancellation,
                              })
                            }
                          />
                        }
                        label="J'ai besoin de résilier mon ancien contrat."
                      />
                    </FormGroup>
                    <Spacing size="s" />
                    {company.cancellation && (
                      <>
                        <TextInput
                          required
                          label="Nom de votre ancienne compagnie d'assurance"
                          variant="outlined"
                          type="phone"
                          value={cancellation.insuranceName}
                          onChange={({ target: { value } }) =>
                            setCancellation({
                              ...cancellation,
                              insuranceName: value,
                            })
                          }
                        />
                        <Spacing size="s" />
                        <TextInput
                          required
                          label="Votre ancien numéro de contrat"
                          variant="outlined"
                          type="phone"
                          value={cancellation.contractNumber}
                          onChange={({ target: { value } }) =>
                            setCancellation({
                              ...cancellation,
                              contractNumber: value,
                            })
                          }
                        />
                        <Spacing size="s" />
                        <Typography>
                          Date de souscription de votre ancien contrat
                        </Typography>
                        <DatePicker
                          maxDate={new Date()}
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                          selected={cancellation.subscribtionDate}
                          onChange={(date: Date) =>
                            setCancellation({
                              ...cancellation,
                              subscribtionDate: date,
                            })
                          }
                        />
                        <Spacing size="s" />
                      </>
                    )}
                    <Spacing size="m" />

                    {loading ? <LinearProgress color="primary" /> :
                      <ButtonGradientPrimaryFullWidth
                        type="submit"
                        disabled={!(id[0] && kbis && rib) || loading}
                      >
                        {text.button}
                      </ButtonGradientPrimaryFullWidth>}

                  </FormContainer>
                  <PriceCard />
                </Columns>
              </form>
            </RightColumn>
            <StyledModal open={open}>
              <ModalContainer>
                <Typography variant="h2">{text.validateTitle}</Typography>
                <Spacing size="m" />
                <ModalGif
                  src={`${process.env.REACT_APP_STRAPI_URL}${text.validateImage && text.validateImage.data.attributes.url
                    }`}
                />
                <Spacing size="s" />
                <ReactMarkdown>{text.validateContent || ""}</ReactMarkdown>

                <Spacing size="s" />
                <ButtonGradientPrimary
                  variant="contained"
                  href="http://lolahealth.fr"
                >
                  {text.validateButton}
                </ButtonGradientPrimary>
              </ModalContainer>
            </StyledModal>
          </>
        )}
      </>
    </Layout>
  );
};

export default DocumentPage;
