import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import { useGlobalState } from "../state";

import { getPricesWithFilter, PriceType } from "../services/PriceService";

import { Spacing } from "./Layout";
import { Price, PriceTotal } from "./Price";
import { CardContainer } from "./CompareCard";

const Card = styled(CardContainer)`
  padding: 32px 16px;
  min-width: 300px;
  height: fit-content;
  position: sticky;
  top: 80px;
`;

const PriceDisplay = styled.span`
  color: #5a2c01;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: center;
  font-weight: 700;
`;

const CategoryTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  padding-bottom: 4px;
  font-weight: 700;
`;

const NumberOfEmployee = styled.span`
  color: #ff9a02;
`;

const Column = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type PriceRowProps = {
  price?: number;
  priceMax?: number;
  priceCompany?: number;
  priceCompanyMax?: number;
  separator?: string;
  before?: string;
};

const PriceRow = ({ price, priceCompany }: PriceRowProps) => (
  <>
    {priceCompany !== 0 && (
      <>
        <Column>
          <Typography fontWeight={300}>À la charge de l'entreprise </Typography>
          <Price price={priceCompany} symbol="/mois" size="m" />
        </Column>
        <Spacing size="s" />
      </>
    )}
    {!(price === 0 || !price) && (
      <>
        <Column>
          <Typography fontWeight={300}>À la charge du salarié</Typography>
          <Price price={price} symbol="/mois" size="m" />
        </Column>
        <Spacing size="s" />
      </>
    )}
  </>
);

const PriceTotalRow = ({
  price,
  priceCompany,
  priceMax,
  priceCompanyMax,
  separator,
  before,
}: PriceRowProps) => (
  <>
    <Column>
      <Typography fontWeight={300}>À la charge de l'entreprise </Typography>
      <PriceTotal
        price={priceCompany}
        priceMax={priceCompanyMax}
        symbol="/mois"
        size="m"
        before={before}
        separator={separator}
      />
    </Column>
    <Spacing size="s" />

    {!(price === 0 || !price) && (
      <>
        <Column>
          <Typography fontWeight={300}>À la charge du salarié</Typography>
          <PriceTotal
            price={price}
            priceMax={priceMax}
            symbol="/mois"
            size="m"
            before={before}
            separator={separator}
          />
        </Column>
        <Spacing size="s" />
      </>
    )}
  </>
);

export const PriceCard = ({ children }: { children?: JSX.Element }) => {
  const [company] = useGlobalState("company");
  const [price] = useGlobalState("price");
  const [alonePrice, setAlonePrice] = useState<Partial<PriceType>>({});
  const multiplyBy = company.participation / 100;

  useEffect(() => {
    getPricesWithFilter({
      structureLabel: "Alone",
      ageLabel: price.attributes?.price_age.data.attributes.label,
      regionLabel: price.attributes?.region.data.attributes.label,
      formulaLabel: price.attributes?.formula.data.attributes.label,
    }).then((prices) => {
      setAlonePrice(prices[0]);
    });
  }, [price]);

  return (
    <Card theme={price.attributes?.formula.data.id === 1 ? "orange" : "red"}>
      <Typography variant="h3">Lola</Typography>
      <Typography variant="h2">
        {price.attributes?.formula.data.attributes.label}
      </Typography>

      <Spacing size="l" />

      <CategoryTitle variant="h4">Par salarié</CategoryTitle>

      <Spacing size="s" />

      <PriceDisplay>
        {price.attributes?.structure.data.attributes.label === "Familly" ? (
          <PriceTotal
            price={alonePrice.attributes?.price}
            priceMax={price.attributes?.price}
            symbol="/mois"
            size="l"
            separator="ou"
          />
        ) : (
          <Price price={price.attributes?.price} symbol="/mois" size="l" />
        )}
      </PriceDisplay>

      <Spacing size="m" />

      {price.attributes?.structure.data.attributes.label === "Familly" ? (
        <PriceTotalRow
          price={
            alonePrice.attributes &&
            alonePrice.attributes?.price * (1 - multiplyBy)
          }
          priceMax={
            price.attributes && price.attributes.price * (1 - multiplyBy)
          }
          priceCompany={
            alonePrice.attributes && alonePrice.attributes?.price * multiplyBy
          }
          priceCompanyMax={
            price.attributes && price.attributes.price * multiplyBy
          }
          separator="ou"
        />
      ) : (
        <PriceRow
          price={
            price.attributes?.price &&
            price.attributes?.price * (1 - multiplyBy)
          }
          priceCompany={
            price.attributes?.price && price.attributes?.price * multiplyBy
          }
        />
      )}
      <Spacing size="l" />

      <CategoryTitle variant="h4">
        Total pour{" "}
        <NumberOfEmployee>{company.numberOfEmployee}</NumberOfEmployee> salariés
      </CategoryTitle>
      <Spacing size="s" />

      <PriceDisplay>
        {price.attributes?.structure.data.attributes.label === "Familly" ? (
          <PriceTotal
            price={
              alonePrice.attributes &&
              alonePrice.attributes?.price * company.numberOfEmployee
            }
            priceMax={
              price.attributes &&
              price.attributes?.price * company.numberOfEmployee
            }
            symbol="/mois"
            size="l"
            separator="et"
            before="Entre"
          />
        ) : (
          <Price
            price={
              price.attributes &&
              price.attributes?.price * company.numberOfEmployee
            }
            symbol="/mois"
            size="l"
            separator="et"
            before="Entre"
          />
        )}
      </PriceDisplay>

      <Spacing size="m" />

      {!(price.attributes?.structure.data.attributes.label === "Familly") ? (
        <PriceRow
          priceCompany={
            price.attributes &&
            price.attributes.price * company.numberOfEmployee * multiplyBy
          }
        />
      ) : (
        <PriceTotalRow
          priceCompany={
            alonePrice.attributes &&
            alonePrice.attributes?.price * company.numberOfEmployee * multiplyBy
          }
          priceCompanyMax={
            price.attributes &&
            price.attributes.price * company.numberOfEmployee * multiplyBy
          }
          separator="et"
          before="Entre"
        />
      )}

      {children}
    </Card>
  );
};
